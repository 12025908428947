<template>
  <div class="card login-form">
    <Header :title="FA_LAN.LOGIN.LOGIN "/>
    <form>
      <div class="mb-4">
        <label for="phone" class="form-label">{{ FA_LAN.LOGIN.PHONE }}</label>
        <input type="number" class="form-control" id="phone" v-model.lazy="user.username">
        <small class="text-danger" v-if="error.phone">{{ FA_LAN.LOGIN.PHONE_NOT_VALID }}</small>
      </div>
      <div class="mb-4">
        <label for="pass" class="form-label">{{ FA_LAN.LOGIN.PASSWORD }}</label>
        <input type="password" class="form-control" id="pass" v-model.lazy="user.password">
        <small class="text-danger" v-if="error.password">{{ FA_LAN.LOGIN.PASSWORD_NOT_VALID }}</small>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-lg btn-success btn-block" @click.prevent="login" :disabled="loader">
          <span v-if="loader" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
          {{ FA_LAN.LOGIN.LOGIN }}
        </button>
      </div>
      <div class="spacer"></div>
      <div>
        <span class="me-2">{{ FA_LAN.LOGIN.NOT_HAVE_ACCOUNT }}</span>
        <router-link class="btn btn-sm btn-light" :to="{name:'Register'}">{{ FA_LAN.LOGIN.REGISTER }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {recaptchChek} from "@/store/webservice";
import {alert} from "@/js/alert/alert";
import {useReCaptcha} from "vue-recaptcha-v3";
import {passwordValidation, phoneValidation, setInputNotValid, setInputValid} from "@/js/validation/input";
import Header from "@/components/Header";

export default {
  name: "Login",
  components: {Header},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const router = useRouter();
    const loader = ref(false);
    const {executeRecaptcha, recaptchaLoaded} = useReCaptcha();
    const tokenValid = ref(false);
    const inputID = reactive({
      username: "phone",
      password: "pass"
    })
    const user = reactive({
      username: "",
      password: ""
    })
    const error = reactive({
      phone: false,
      password: false,
    })

    async function login() {
      checkPhone();
      checkPassword();

      if (error.password || error.phone) {
        return;
      }

      loader.value = true

      await recaptcha();

      if (!tokenValid.value) {
        loader.value = false;
        return;
      }

      await store.dispatch('apiKey/login', user)

      loader.value = false;

      if (store.getters["apiKey/get"]) {
        await router.push({name: 'Fetch'});
      }
    }

    watch(user, () => {
      if (user.username.length !== 0) {
        checkPhone();

      }
      if (user.password.length !== 0) {
        checkPassword();
      }
    })

    function checkPassword() {
      const passValid = passwordValidation(user.password);

      if (passValid) {
        error.password = false;
        setInputValid(inputID.password);

      } else {
        error.password = true;
        setInputNotValid(inputID.password);


      }
    }

    function checkPhone() {
      const phoneNumber = phoneValidation(user.username);

      if (phoneNumber) {
        error.phone = false;
        setInputValid(inputID.username);
        user.username = phoneNumber;

      } else {
        error.phone = true;
        setInputNotValid(inputID.username);

      }
    }

    async function recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha('login')

      // Do stuff with the received token.
      await recaptchChek(token)
          .then(function (response) {
            if (response.data.success === true && response.data.score >= .5) {
              tokenValid.value = true;

            } else {
              alert('error', FA_LAN.LOGIN.RECAPTCHA_ERROR)
              tokenValid.value = false;

            }
          })
    }

    return {user, FA_LAN, loader, error, login}
  }
}
</script>

<style scoped>

</style>