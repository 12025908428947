<template>
  <router-view v-slot="{ Component }">
    <transition name="slide-fade">
      <component :is="Component"/>
    </transition>
  </router-view>
</template>

<script>
export default {
  name: "Template"
}
</script>

<style scoped>

</style>