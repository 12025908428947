<template>
  <div class="card new-item-form">
    <back :title="FA_LAN.CATALOG.NEW "/>
    <form>
      <div class="mb-4">
        <label for="name" class="form-label">{{ FA_LAN.CATALOG.NAME }}</label>
        <input type="text" class="form-control" id="name" v-model.lazy="catalog.name"
               :placeholder="FA_LAN.CATALOG.NAME_EXAMPLE">
        <small class="text-danger" v-if="error.name">{{ FA_LAN.CATALOG.NAME_NOT_VALID }}</small>
      </div>
      <div class="mb-4">
        <Username :username="catalog.username" :error="error" v-model="catalog.username"/>
      </div>
      <div class="mb-4">
        <label for="pass" class="form-label">{{ FA_LAN.CATALOG.PASSWORD }}</label>
        <input type="password" class="form-control" id="pass" v-model.lazy="password"
               :placeholder="FA_LAN.CATALOG.PASSWORD_PLACEHOLDER">
        <small class="text-danger" v-if="error.password">{{ FA_LAN.CATALOG.PASSWORD_NOT_VALID }}</small>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-lg btn-success btn-block" @click.prevent="submit" :disabled="loader">
          <span v-if="loader" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
          {{ FA_LAN.CATALOG.SUBMIT_NEW }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {post} from "@/store/webservice";
import {passwordValidation, setInputNotValid, setInputValid} from "@/js/validation/input";
import {alert} from "@/js/alert/alert";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import Back from "@/components/Back";
import Username from "@/components/input/Username";
import {getCssColor} from "@/js/css/color";
import {usernameValid} from "@/js/input/username";

export default {
  name: "New",
  components: {Back, Username},
  setup() {
    const FA_LAN = persian;
    const loader = ref(false);
    const router = useRouter();
    const store = useStore();
    const password = ref('');
    const catalogs = computed(() => store.getters["catalog/get"])
    const submitted = ref(false);
    const colors = getCssColor();
    const inputID = reactive({
      name: "name",
      username: "username",
      password: "pass",
    })
    const catalog = reactive({
      name: "",
      username: "",
      background_color: colors.background,
      text_color: colors.text
    })
    const error = reactive({
      name: false,
      username: false,
      usernameShort: false,
      password: false,
    })

    watch(catalog, () => {
      if (catalog.name.length !== 0) {
        checkName();
      }

      if (catalog.username.length !== 0) {
        checkUsername();
      }

    })

    watch(password, () => {
      if (password.value.length !== 0) {
        checkPass();
      }
    })

    onMounted(() => {
      if (catalogs.value.length !== 0) {
        router.push({name: "Fetch"})
      }
    })

    async function submit() {
      checkName();
      await checkUsername();
      checkPass();

      if (error.username || error.name || error.usernameShort || error.password) {
        return;
      }

      loader.value = true;

      await updatePassword();

      if (error.password) {
        loader.value = false;
        return;
      }

      await post('v1/catalog/new', catalog)
          .then(response => {
            if (response.data.code === 200) {
              alert('success', response.data.description);
              submitted.value = true;

            } else {
              alert('error', response.data.description);
            }
          })

      loader.value = false;

      if (submitted.value) {
        await router.push({name: "Fetch"})
      }
    }

    async function checkUsername() {

      if (catalog.username.length <= 3) {
        error.usernameShort = true;
        setInputNotValid(inputID.username);
        return;

      } else {
        error.usernameShort = false;
        setInputValid(inputID.username);
      }

      const valid = await usernameValid(catalog.username, null)

      if (valid) {
        error.username = false;
        setInputValid(inputID.username);

      } else {
        error.username = true;
        setInputNotValid(inputID.username);
      }
    }

    async function updatePassword() {
      checkPass();

      await post('v1/user/updatePassword', {password: password.value})
          .then(response => {
            if (response.data.code !== 200) {
              error.password = true;
              setInputNotValid(inputID.password);
              alert('error', response.data.description)

            } else {
              error.password = false;
              setInputValid(inputID.password);
            }
          })
    }

    function checkName() {

      if (catalog.name.length <= 3) {
        error.name = true;
        setInputNotValid(inputID.name);

      } else {
        error.name = false;
        setInputValid(inputID.name);

      }
    }

    function checkPass() {
      if (passwordValidation(password.value)) {
        error.password = false;
        setInputValid(inputID.password)
      } else {
        error.password = true;
        setInputNotValid(inputID.password)
      }
    }

    return {FA_LAN, catalog, error, loader, password, submit}
  }
}
</script>

<style scoped>
.col-7 {
  width: 58.33333333% !important;
}

.ltr {
  direction: ltr;
}

.btn-sm {
  padding: 0.25rem 0.5rem !important;
}
</style>