import {initializeApp} from "firebase/app"
import 'firebase/firebase-messaging'

const firebaseConfig = {
    apiKey: "AIzaSyAKVbrR--G3XLaY2jVpitmKqS-FqzjLKOQ",
    authDomain: "education-app-b050f.firebaseapp.com",
    projectId: "education-app-b050f",
    storageBucket: "education-app-b050f.appspot.com",
    messagingSenderId: "325849115090",
    appId: "1:325849115090:web:ac4a44399990ad84e6000b",
    measurementId: "G-R22875HSZH"
}

initializeApp(firebaseConfig)
