<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       :id="id">
    <div class="category-form mx-auto mt-3 mb-3">
      <form>
        <div class="mb-4 col-9">
          <label class="form-label">{{ FA_LAN.CATEGORY.NAME }}</label>
          <div class="d-flex align-items-center">
            <input type="text" class="form-control col-8" :id="'name'+id" v-model.lazy="category.name">
            <div class="form-check form-switch col-4 ms-3">
              <input class="form-check-input me-1" type="checkbox" id="active" v-model="category.active">
              <label v-if="category.active" class="form-check-label mt-1" for="active">
                {{ FA_LAN.CATEGORY.ACTIVE }}</label>
              <label v-else class="form-check-label mt-1" for="active">{{ FA_LAN.CATEGORY.NOT_ACTIVE }}</label>
            </div>
          </div>
          <small class="text-danger" v-if="error">{{ FA_LAN.CATEGORY.NAME_NOT_VALID }}</small>
        </div>
        <div class="d-flex">
          <div class="d-grid gap-2 col-9">
            <button class="btn btn-lg btn-success btn-block" @click.prevent="update"
                    :disabled="loaderEdit || loaderDel">
            <span v-if="loaderEdit" class="spinner-border spinner-border-sm me-1" role="status"
                  aria-hidden="true"></span>
              {{ FA_LAN.CATEGORY.EDIT }}
            </button>
          </div>
          <div class="d-grid gap-2 col-3 ms-2">
            <button class="btn btn-lg btn-danger btn-block" @click.prevent="deleteCat"
                    :disabled="loaderEdit || loaderDel">
            <span v-if="loaderDel" class="spinner-border spinner-border-sm me-1" role="status"
                  aria-hidden="true"></span>
              {{ FA_LAN.CATEGORY.DELETE }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {reactive, ref, watch} from "vue";
import {setInputNotValid, setInputValid} from "@/js/validation/input";
import {post} from "@/store/webservice";
import {alert} from "@/js/alert/alert";
import {useStore} from "vuex";

export default {
  name: "EditCategory",
  props: ['id', 'item'],
  setup(props) {
    const FA_LAN = persian;
    const store = useStore();
    const inputID = ref('name' + props.id);
    const loaderDel = ref(false);
    const loaderEdit = ref(false);
    const category = reactive({
      category_id: props.item.id,
      name: props.item.name,
      active: props.item.active,
    })
    const error = ref(false);

    watch(category, () => {
      if (category.name.length !== 0) {
        checkName()
      }
    })

    function checkName() {
      error.value = category.name.length <= 3;

      if (error.value) {
        setInputNotValid(inputID.value)
      } else {
        setInputValid(inputID.value)
      }
    }

    async function update() {
      checkName();

      if (error.value) {
        return;
      }

      loaderEdit.value = true;

      if (!category.active) {
        category.active = 0;
      }

      await post('v1/catalog/category/update', category)
          .then(response => {
            if (response.data.code === 200) {
              alert('success', response.data.description);
            } else {
              alert('error', response.data.description);
            }
          })

      await getCategoryList();
      await getItemList();

      loaderEdit.value = false;
    }

    async function deleteCat() {
      loaderDel.value = true;

      await post('v1/catalog/category/delete', {category_id: props.item.id})
          .then(response => {
            if (response.data.code === 200) {
              alert('success', response.data.description);
            } else {
              alert('error', response.data.description);
            }
          })

      await getCategoryList();
      await getItemList();

      loaderDel.value = false;
    }

    async function getCategoryList() {
      await store.dispatch('category/getList');
    }

    async function getItemList() {
      await store.dispatch('item/getList');
    }

    return {FA_LAN, category, error, loaderEdit, loaderDel, update, deleteCat}
  }
}
</script>

<style scoped>

</style>