<template>
  <div class="d-flex justify-content-center align-items-center mt-3 mb-3 vh-100">
    <div class="spinner-border spinner text-black-50" role="status"></div>
  </div>
</template>

<script>
export default {
  name: "Loading",
}
</script>

<style scoped>

</style>