export function phoneValidation(phone) {
    const phoneNUmber = String(phone);
    const first = phoneNUmber.charAt(0);
    let result;

    if ((first == '0' && phoneNUmber.length !== 11) || (first != '0' && phoneNUmber.length !== 10)) {
        result = false;

    } else {
        if (first != '0' && phoneNUmber.length === 10) {
            result = '0' + phone;
        } else {
            result = phone;
        }
    }

    return result;
}

export function passwordValidation(password) {
    return password.length >= 6
}

export function otpValidation(otp) {
    const isNum = /^\d+$/.test(otp);

    if (isNum) {
        return String(otp).length === 6;
    } else {
        return false;
    }
}

export function setInputValid(id){
    const input = document.getElementById(id);

    input.classList.remove('is-invalid');
    input.classList.add('is-valid');
}

export function setInputNotValid(id){
    const input = document.getElementById(id);

    input.classList.remove('is-valid');
    input.classList.add('is-invalid');
}