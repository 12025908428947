<template>
  <transition name="slide-fade">
    <Check v-if="codeSent"/>
    <Send v-else/>
  </transition>
</template>

<script>
import Send from "@/components/otp/Send";
import Check from "@/components/otp/Check";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "Register",
  components: {Check, Send},
  setup() {
    const store = useStore();
    const codeSent = computed(() => store.getters["apiKey/getCodeValid"])

    return {codeSent}
  }
}
</script>

<style scoped>

</style>