import pwa from "./includes/pwa";
import main from "@/fa-IR/includes/main";
import login from "@/fa-IR/includes/login";
import catalog from "@/fa-IR/includes/catalog";
import category from "@/fa-IR/includes/category";
import item from "@/fa-IR/includes/item";
import review from "@/fa-IR/includes/review";
import push from "@/fa-IR/includes/push";
import contactus from "@/fa-IR/includes/contactus";
import update from "@/fa-IR/includes/update";

const persian = {
    PWA: pwa,
    MAIN: main,
    LOGIN: login,
    CATALOG: catalog,
    CATEGORY: category,
    ITEM: item,
    REVIEW: review,
    PUSH: push,
    CONTACTUS: contactus,
    UPDATE: update,
}

export default persian;