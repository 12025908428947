<template>
  <div class="card category-form">
    <back :title="FA_LAN.CATALOG.EDIT_CATALOG "/>
    <form>
      <div class="mb-4">
        <label for="name" class="form-label">{{ FA_LAN.CATALOG.NAME }}</label>
        <input type="text" class="form-control" id="name" v-model.lazy="updatedCatalog.name">
        <small class="text-danger" v-if="error.name">{{ FA_LAN.CATALOG.NAME_NOT_VALID }}</small>
      </div>
      <div class="mb-4">
        <Username :username="updatedCatalog.username" :error="error" v-model="updatedCatalog.username"/>
      </div>
      <div class="mb-4">
        <label class="form-label">{{ FA_LAN.CATALOG.DESCRIPTION }}</label>
        <textarea class="form-control" id="description" v-model.lazy="updatedCatalog.description" rows="2"></textarea>
        <small class="text-danger" v-if="error.description">{{ FA_LAN.CATALOG.DESCRIPTION_NOT_VALID }}</small>
      </div>
      <div class="mb-4 d-flex">
        <div class="col-6">
          <label for="backColor" class="form-label text-bg-catalog">{{ FA_LAN.CATALOG.BACKGROUND_COLOR }}</label>
          <input type="color" class="form-control form-control-color" id="backColor"
                 v-model="updatedCatalog.background_color">
        </div>
        <div class="col-6">
          <label for="backColor" class="form-label off-price">{{ FA_LAN.CATALOG.TEXT_COLOR }}</label>
          <input type="color" class="form-control form-control-color" id="textColor"
                 v-model="updatedCatalog.text_color">
        </div>
      </div>
      <div class="mb-4">
        <UploadImage role="catalog" :image="catalog.logo" v-model="image"/>
      </div>
      <div>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-lg btn-success btn-block" @click.prevent="update" :disabled="loader">
            <span v-if="loader" class="spinner-border spinner-border-sm me-1" role="status"
                  aria-hidden="true"></span>
          {{ FA_LAN.CATALOG.EDIT }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {setInputNotValid, setInputValid} from "@/js/validation/input";
import {post} from "@/store/webservice";
import {alert} from "@/js/alert/alert";
import {setCssColor} from "@/js/css/color";
import UploadImage from "@/components/input/UploadImage";
import {uploadImage} from "@/js/input/upload";
import Back from "@/components/Back";
import Username from "@/components/input/Username";
import {usernameValid} from "@/js/input/username";

export default {
  name: "Edit",
  components: {Username, UploadImage, Back},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const loader = ref(false);
    const catalog = computed(() => store.getters["catalog/getItem"]);
    const image = ref(null);
    const updatedCatalog = reactive({
      catalog_id: catalog.value.id,
      username: catalog.value.username,
      name: catalog.value.name,
      description: catalog.value.description,
      logo: null,
      background_color: catalog.value.background_color,
      text_color: catalog.value.text_color,
      active: catalog.value.active,
    })

    const error = reactive({
      logo: false,
      name: false,
      description: false,
      username: false,
      usernameShort: false,
    });
    const inputID = reactive({
      logo: 'logo',
      name: 'name',
      description: 'description',
      username: 'username',
    });


    watch(updatedCatalog, () => {
      if (updatedCatalog.name.length !== 0) {
        checkName()
      }

      if (updatedCatalog.description) {
        if (updatedCatalog.description.length !== 0) {
          checkDescription()
        }
      }

      if (updatedCatalog.username.length !== 0) {
        checkUsername();
      }

      changeColor();
    })

    onMounted(() => {
      changeColor();
    })

    function checkName() {
      error.name = updatedCatalog.name.length <= 3;

      if (error.name) {
        setInputNotValid(inputID.name)
      } else {
        setInputValid(inputID.name)
      }
    }

    function checkDescription() {
      error.description = updatedCatalog.description.length <= 10;

      if (error.description) {
        setInputNotValid(inputID.description)
      } else {
        setInputValid(inputID.description)
      }
    }

    async function update() {
      checkName();
      await checkUsername();

      if (error.name || error.description || error.username || error.usernameShort) {
        return;
      }

      loader.value = true;

      if (image.value) {
        await upload();

      }

      if (error.logo) {
        return;
      }

      if (!updatedCatalog.active) {
        updatedCatalog.active = 0;
      }

      await post('v1/catalog/update', updatedCatalog)
          .then(response => {
            if (response.data.code === 200) {
              alert('success', response.data.description);
            } else {
              alert('error', response.data.description);
            }
          })

      await getCatalog();

      loader.value = false;
    }

    async function getCatalog() {
      await store.dispatch('catalog/getList');
    }


    function changeColor() {
      setCssColor(updatedCatalog.background_color, updatedCatalog.text_color)
    }

    async function upload() {
      const fileID = await uploadImage(image.value, 'catalog')

      if (!fileID) {
        error.logo = true;

      } else {
        error.logo = false;
        updatedCatalog.logo = fileID;

      }

    }

    async function checkUsername() {

      if (updatedCatalog.username.length <= 3) {
        error.usernameShort = true;
        setInputNotValid(inputID.username);
        return;

      } else {
        error.usernameShort = false;
        setInputValid(inputID.username);
      }

      const valid = await usernameValid(updatedCatalog.username, catalog.value.id)

      if (valid) {
        error.username = false;
        setInputValid(inputID.username);

      } else {
        error.username = true;
        setInputNotValid(inputID.username);
      }
    }

    return {FA_LAN, updatedCatalog, error, loader, image, catalog, update}
  }

}
</script>

<style scoped>

</style>