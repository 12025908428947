import {createStore} from 'vuex'
import {apiKey} from "@/store/modules/apiKey";
import {catalog} from "@/store/modules/catalog";
import {category} from "@/store/modules/category";
import {item} from "@/store/modules/item";
import {pwa} from "@/store/modules/pwa";

export default createStore({
    modules: {apiKey, catalog, category, item, pwa}
})
