<template>
  <div class="card category-form">
    <back :title="FA_LAN.CATEGORY.MANAGE_CATEGORY "/>
    <button class="btn btn-lg btn-success btn-block" data-bs-toggle="offcanvas" data-bs-target="#newcategory"
            aria-controls="offcanvasExample">
      {{ FA_LAN.CATEGORY.NEW }}
    </button>
    <div class="spacer"></div>
    <NewCategory/>
    <p class="text-muted" v-if="checkEmpty()">{{ FA_LAN.CATEGORY.EMPTY_CATEGORY }}</p>
    <div v-else v-for="(category,index) in categories" :key="category.id">
      <div v-if="index!==0" class="spacer m-2 p-0"></div>
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <p class="m-0">
            {{ category.name }}
            <span v-if="category.active" class="text-success small">({{ FA_LAN.CATEGORY.ACTIVE }})</span>
            <span v-else class="text-black-50 small">({{ FA_LAN.CATEGORY.NOT_ACTIVE }})</span>
          </p>
        </div>
        <button class="btn btn-sm btn-light me-2" data-bs-toggle="offcanvas"
                :data-bs-target="'#editCategory'+category.id"
                aria-controls="offcanvasExample">
          {{ FA_LAN.CATEGORY.EDIT }}
        </button>
        <EditCategory :id="'editCategory'+category.id" :item="category"/>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import EditCategory from "@/components/convas/Category/EditCategory";
import NewCategory from "@/components/convas/Category/NewCategory";
import Back from "@/components/Back";

export default {
  name: "Category",
  components: {NewCategory, EditCategory, Back},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const router = useRouter();
    const categories = computed(() => store.getters["category/get"]);
    const catalogID = computed(() => store.getters["catalog/getItemId"]);

    onMounted(() => {
      if (!catalogID.value) {
        router.push({name: 'Fetch'})
      }

      getCategoryList();
    })


    function checkEmpty() {
      return categories.value.length === 0;
    }

    async function getCategoryList() {
      await store.dispatch('category/getList');
    }

    return {FA_LAN, categories, checkEmpty}
  }
}
</script>

<style scoped>

</style>