<template>
  <div>
    <div class="d-grid gap-2">
      <label for="logo" class="btn btn-secondary">{{ FA_LAN.CATALOG.LOGO }}</label>
    </div>
    <small :class="{'text-danger':error , 'text-muted':!error}"> {{ getErrorText() }}
    </small>
    <input type="file" accept="image/*" class="hidden" id="logo" @change="preview">
    <div class="text-center" :class="{'hidden':!logo}">
      <img id="image" class="img-thumbnail" :class="{'rounded-circle':role=='catalog' ,'square':role=='catalog'}"
           :src="logo"/>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {onMounted, ref} from "vue";
import {post} from "@/store/webservice";

export default {
  name: "UploadImage",
  props: ['image', "role"],
  setup(props, {emit}) {
    const FA_LAN = persian;
    const logo = ref(props.image);
    const error = ref(false);
    const maxFileSize = ref(0);
    const maxInputSize = ref(10 * 1024 * 1024);

    onMounted(async () => {
      await getSetting();
    })

    function preview(e) {
      let file = e.target.files[0];

      if (file.size > maxInputSize.value) {
        error.value = true;
        logo.value = props.image;
        emit('update:modelValue', null);
        return;
      }

      if (file.size > maxFileSize.value * 1024) {
        resizeImage(file, newFile => {

          error.value = false;
          logo.value = URL.createObjectURL(newFile);
          emit('update:modelValue', newFile);
        })
        return;
      }

      error.value = false;
      logo.value = URL.createObjectURL(file);

      emit('update:modelValue', file);

    }

    async function getSetting() {
      await post('v1/catalog/images/getSetting')
          .then(response => {
            maxFileSize.value = response.data.data.max_file_size_KB
          })
    }

    function getErrorText() {
      return props.role === 'catalog' ? FA_LAN.CATALOG.LOGO_MAX_SIZE : FA_LAN.ITEM.IMAGE_MAX_SIZE
    }

    function resizeImage(file, callback) {
      // Ensure it's an image
      if (file.type.match(/image.*/)) {

        // Load the image
        const reader = new FileReader();
        reader.onload = function (readerEvent) {
          const image = new Image();
          image.onload = function () {

            // Resize the image
            let canvas = document.createElement('canvas'),
                max_size = 1024,
                width = image.width,
                height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = "#ffffff"
            ctx.fillRect(0, 0, width, height);
            ctx.drawImage(image, 0, 0, width, height);
            const dataUrl = canvas.toDataURL('image/jpeg');
            const resizedImage = dataURLToBlob(dataUrl);

            callback(resizedImage)
          }
          image.src = readerEvent.target.result;
        }
        reader.readAsDataURL(file);
      }
    }

    /* Utility function to convert a canvas to a BLOB */
    function dataURLToBlob(dataURL) {
      const BASE64_MARKER = ';base64,';
      let parts, contentType, raw;
      if (dataURL.indexOf(BASE64_MARKER) == -1) {
        parts = dataURL.split(',');
        contentType = parts[0].split(':')[1];
        raw = parts[1];

        const blob = new Blob([raw], {type: contentType});
        return blobToFile(blob);
      }

      parts = dataURL.split(BASE64_MARKER);
      contentType = parts[0].split(':')[1];
      raw = window.atob(parts[1]);
      const rawLength = raw.length;

      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      const blob = new Blob([uInt8Array], {type: contentType});
      return blobToFile(blob);

    }

    /* End Utility function to convert a canvas to a BLOB      */

    function blobToFile(theBlob, fileName = "resized_image.jpg") {
      return new File([theBlob], fileName)
    }


    return {FA_LAN, error, logo, preview, getErrorText}
  }
}
</script>

<style scoped>

</style>