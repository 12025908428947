const push={
    BANNER_TITLE:"اخبار و اطلاعیه‌های مهم را از طریق نوتیفیکشن دریافت کنید؟",
    BANNER_DES:"ما هم از پیام‌های تبلیغاتی بیزاریم و فقط اخبار و اطلاعیه‌های مهم را اطلاع رسانی می‌کنیم",
    INSTALL_NOW:"بعد از کلیک، روی Allow/اجازه، کلیک کنید",
    INSTALL_SUCCESS:"اجازه ارسال نوتیفیکشن داده شد",
    INSTALL_FAIL:"ارسال نوتیفیکشن رد شد",
    ACTIVE_PUSH:"فعال کردن دریافت اطلاعیه‌ها",
    ACTIVE_BEFORE:"قبلاً دریافت اطلاعیه‌ها فعال شده است",
}

export default push