<template>
  <div class="card category-form">
    <back :title="titleText"/>
    <form>
      <div class="mb-4" :class="{'col-9':existItem}">
        <label class="form-label">{{ FA_LAN.ITEM.NAME }}</label>
        <div class="d-flex align-items-center ">
          <input type="text" class="form-control" :class="{'col-8':existItem}" :id="'name'+id" v-model.lazy="item.name">
          <div class="form-check form-switch col-5 ms-3" v-if="existItem">
            <input class="form-check-input me-1" type="checkbox" id="active" v-model="item.active">
            <label v-if="item.active" class="form-check-label mt-1" for="active">
              {{ FA_LAN.ITEM.ACTIVE }}</label>
            <label v-else class="form-check-label mt-1" for="active">{{ FA_LAN.ITEM.NOT_ACTIVE }}</label>
          </div>
        </div>
        <small class="text-danger" v-if="error.name">{{ FA_LAN.ITEM.NAME_NOT_VALID }}</small>
      </div>
      <div class="mb-4">
        <label class="form-label">{{ FA_LAN.ITEM.DESCRIPTION }}</label>
        <textarea class="form-control" :id="'description'+id" v-model.lazy="item.description"
                  rows="3"></textarea>
        <small class="text-danger" v-if="error.description">{{ FA_LAN.ITEM.DESCRIPTION_NOT_VALID }}</small>
      </div>
      <div class="mb-4">
        <label class="form-label">{{ FA_LAN.ITEM.MAIN_PRICE }}</label>
        <input type="number" class="form-control" :id="'mainPrice'+id" v-model.number="item.main_price">
        <small class="text-danger" v-if="error.main_price">{{ FA_LAN.ITEM.MAIN_PRICE_NOT_VALID }}</small>
      </div>
      <div class="mb-4">
        <label class="form-label">{{ FA_LAN.ITEM.PRICE }}</label>
        <input type="number" class="form-control" :id="'price'+id" v-model.number="item.price">
        <small class="text-danger" v-if="error.price">{{ FA_LAN.ITEM.PRICE_NOT_VALID }}</small>
      </div>
      <div class="mb-4">
        <label class="form-label">{{ FA_LAN.ITEM.CATEGORY }}</label>
        <div class="d-flex">
          <div class="col-9">
            <select class="form-select" aria-label="Default select example" v-model="item.category_id">
              <option v-for="category in categories" :value="category.id" :key="category.id">
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="col-3 ms-2">
            <router-link :to="{name:'Category'}" class="btn btn-sm btn-light">
              {{ FA_LAN.CATEGORY.EDIT }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <UploadImage role="item" :image="getImage(existItem)" v-model="image"/>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-lg btn-success btn-block" @click.prevent="submit" :disabled="loader">
            <span v-if="loader" class="spinner-border spinner-border-sm me-1" role="status"
                  aria-hidden="true"></span>
          {{ buttonText }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import UploadImage from "@/components/input/UploadImage";
import Back from "@/components/Back";
import persian from "@/fa-IR";
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {setInputNotValid, setInputValid} from "@/js/validation/input";
import {post} from "@/store/webservice";
import {alert} from "@/js/alert/alert";
import {uploadImage} from "@/js/input/upload";

export default {
  name: "ItemForm",
  props: ['existItem'],
  components: {UploadImage, Back},
  setup(props) {
    const FA_LAN = persian;
    const store = useStore();
    const loader = ref(false);
    const catalogID = computed(() => store.getters["catalog/getItemId"]);
    const categories = computed(() => store.getters["category/get"]);
    const image = ref(null);
    const buttonText = ref(FA_LAN.ITEM.SUBMIT);
    const titleText = ref(FA_LAN.ITEM.NEW);
    const url = ref('v1/catalog/item/new');
    const id = ref(props.existItem ? props.existItem.id : 0);

    const item = reactive({
      item_id: 0, //in new, ignored, in update needed
      catalog_id: catalogID.value,  //in new, needed, in update ignored
      category_id: null,
      name: "",
      description: "",
      images: null,
      main_price: "",
      price: "",
      active: true
    })
    const error = reactive({
      name: false,
      description: false,
      images: false,
      main_price: false,
      price: false,
    });
    const inputID = reactive({
      name: 'name' + id.value,
      description: 'description' + id.value,
      main_price: 'mainPrice' + id.value,
      price: 'price' + id.value,
    });

    onMounted(() => {
      getFunction();

      if (props.existItem) {//set value with exiting item
        setExistingItem();
      }
    })


    watch(item, () => {
      if (item.name.length !== 0) {
        checkName()
      }

      if (item.description.length !== 0) {
        checkDescription()
      }

      if (item.main_price.length !== 0) {
        checkMainPrice()
      }

      if (item.price.length !== 0) {
        checkPrice()
      }
    })

    function checkName() {
      error.name = item.name.length <= 5;

      if (error.name) {
        setInputNotValid(inputID.name)
      } else {
        setInputValid(inputID.name)
      }
    }


    function checkDescription() {
      error.description = item.description.length <= 10 || item.description.length > 254;

      if (error.description) {
        setInputNotValid(inputID.description)
      } else {
        setInputValid(inputID.description)
      }
    }

    function checkMainPrice() {
      error.main_price = item.main_price.length <= 0;

      if (error.main_price) {
        setInputNotValid(inputID.main_price)
      } else {
        setInputValid(inputID.main_price)
      }
    }

    function checkPrice() {
      error.price = item.price.length <= 0;

      if (error.price) {
        setInputNotValid(inputID.price)
      } else {
        setInputValid(inputID.price)
      }
    }

    async function submit() {
      checkName();
      checkDescription();
      checkMainPrice();
      checkPrice();

      if (error.name || error.description || error.price || error.main_price) {
        return;
      }

      loader.value = true;

      if (image.value) {
        await upload();
      }

      if (error.images) {
        return;
      }

      if (!item.active) {
        item.active = 0;
      }

      await post(url.value, item)
          .then(response => {
            if (response.data.code === 200) {
              alert('success', response.data.description);
            } else {
              alert('error', response.data.description);
            }
          })

      await getItemList();

      loader.value = false;
    }

    async function getItemList() {
      await store.dispatch('item/getList');
    }

    async function upload() {
      const fileID = await uploadImage(image.value, 'item')

      if (!fileID) {
        error.images = true;

      } else {
        error.images = false;
        item.images = [fileID]; //must be array
      }
    }

    function getFunction() {
      if (props.existItem) {
        buttonText.value = FA_LAN.ITEM.EDIT_BUTTON;
        titleText.value = FA_LAN.ITEM.EDIT_BUTTON;
        url.value = 'v1/catalog/item/update';
      }
    }

    function setExistingItem() {
      item.item_id = props.existItem.id;
      item.category_id = props.existItem.catalog_category_id;
      item.name = props.existItem.name;
      item.description = props.existItem.description;
      item.main_price = props.existItem.main_price;
      item.price = props.existItem.price;
      item.active = props.existItem.active;
    }

    function getImage(eItem) {
      if (eItem) {
        if (eItem.images.length === 0) {
          return '';
        } else {
          return eItem.images[0]
        }
      } else {
        return '';
      }
    }

    return {FA_LAN, item, error, loader, categories, image, buttonText, titleText, id, submit, getImage}
  }
}
</script>

<style scoped>

</style>