const category = {
    NEW: "ایجاد دسته جدید",
    NAME: "نام دسته",
    NAME_NOT_VALID: "نام دسته باید بیشتر از ۳ حرف باشد",
    SUBMIT:"ثبت دسته",
    EDIT:"ویرایش",
    DELETE:"حذف",
    EMPTY_CATEGORY:"دسته‌ای تعریف نکرده‌اید",
    ACTIVE:"فعال",
    NOT_ACTIVE:"غیرفعال",
    MANAGE_CATEGORY:"مدیریت دسته‌ها",
}

export default category