const catalog = {
    NEW: "ایجاد کاتالوگ جدید",
    NAME: "نام کاتالوگ",
    NAME_EXAMPLE: "مثلاً محصولات گروه نگاه/ کاتالوک گروه نگاه یا غیره",
    SUBMIT_NEW: "ثبت کاتالوگ",
    USERNAME: "نام کاربری",
    BASE_URL: "https://instacatalog.ir/",
    CHECK_USERNAME: "بررسی",
    NAME_NOT_VALID: "نام وارد شده باید بیشتر از ۳ حرف باشد",
    USERNAME_NOT_VALID: "نام کاربری قبلاً انتخاب شده است",
    USERNAME_SHORT: "نام کاربری باید بیشتر ۳ حرف باشد",
    PASSWORD: "گذرواژه",
    PASSWORD_NOT_VALID: "گذرواژه باید حداقل ۶ حرف باشد",
    PASSWORD_PLACEHOLDER: "برای ورود به حساب کاربری‌تان",
    CATEGORY_MANAGE: "مدیریت دسته‌ها",
    NEW_ITEM: "محصول/خدمات جدید",
    EDIT: "ویرایش",
    DESCRIPTION: "توضیحات",
    LOGO: "آپلود عکس/لوگو",
    LOGO_MAX_SIZE: "عکس انتخاب شده باید کمتر از ۱۰ مگابایت و مربعی باشد",
    DESCRIPTION_NOT_VALID: "توضیحات وارد شده باید بیشتر از ۱۰ حرف باشد",
    EDIT_CATALOG: "ویرایش کاتالوگ",
    BACKGROUND_COLOR: "رنگ پس زمینه",
    TEXT_COLOR: "رنگ متن تخفیف",
    SHOW_CATALOG: "مشاهده",
    COPY_CATALOG_LINK: "کپی کردن",
    COPY_SUCCESS: "آدرس کاتالوک کپی شد! فقط مونده منتشر کنی",
    CATALOG_URL: "آدرس اختصاصی کاتالوگ شما: ",
}
export default catalog