<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       id="pwa">
    <div class="category-form mx-auto mt-3 mb-3">
      <p class="text-center mt-2 mb-3" id="offcanvasBottomLabel">
        {{ FA_LAN.PWA.BANNER_DES }}</p>
      <div class="d-grid gap-2">
        <button type="button" class="btn btn-lg btn-success" @click="install">{{ FA_LAN.PWA.INSTALL_NOW }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/index";
import {computed} from "vue";
import {alert} from "@/js/alert/alert";
import {useStore} from "vuex";

export default {
  name: "PWABanner",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    let installPromptEvent = computed(() => store.getters["pwa/getEvent"]);

    function install() {
      installPromptEvent.value.prompt(); //install pwa

      //get user action
      installPromptEvent.value.userChoice.then((choiceResult) => {

        if (choiceResult.outcome === 'accepted') {
          alert('success', FA_LAN.PWA.INSTALL_SUCCESS)
        } else {
          alert('error', FA_LAN.PWA.INSTALL_FAIL)
        }

        //empty variable after user choice his action,it used and we can use again
        installPromptEvent.value = null;

        disMissBanner()
      })

    }

    function disMissBanner() {
      const app = document.getElementById("pwa");
      app.style.visibility = "hidden";
    }



    return {FA_LAN, install, disMissBanner}
  }
}
</script>

<style scoped>

</style>