import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/Login";
import Register from "@/views/Register";
import NewCatalog from "@/views/catalog/New";
import Category from "@/views/catalog/category/Category";
import CatalogTemp from "@/views/catalog/Template";
import Catalogs from "@/views/catalog/List";
import CatalogEdit from "@/views/catalog/Edit";
import Fetch from "@/views/Fetch";
import ItemTemp from "@/views/catalog/item/Template"
import NewItem from "@/views/catalog/item/NewItem";
import EditItem from "@/views/catalog/item/EditItem";

const routes = [
    {path: '/', name: 'Home', component: Home},
    {path: '/login', name: 'Login', component: Login},
    {path: '/register', name: 'Register', component: Register},
    {path: '/fetch', name: 'Fetch', component: Fetch},
    {
        path: '/catalog', name: 'CatalogTemp', component: CatalogTemp, children: [
            {path: '', name: 'CatalogList', component: Catalogs},
            {path: 'new', name: 'NewCatalog', component: NewCatalog},
            {path: 'category', name: 'Category', component: Category},
            {path: 'edit', name: 'EditCatalog', component: CatalogEdit},
            {
                path: 'item', name: 'ItemTemp', component: ItemTemp, children: [
                    {path: 'new', name: 'NewItem', component: NewItem},
                    {path: 'edit/:id', name: 'EditItem', component: EditItem},
                ]
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
