const item = {
    NEW: "ایجاد محصول/خدمات جدید",
    NAME: "نام محصول/خدمات",
    NAME_NOT_VALID: "نام باید بیشتر از ۵ حرف باشد",
    SUBMIT:"ثبت محصول/خدمات جدید",
    EDIT_BUTTON:"ویرایش محصول/خدمات",
    EDIT:"ویرایش",
    DELETE:"حذف",
    SAVE:"ذخیره",
    EMPTY_ITEM:"محصول/خدماتی تعریف نکرده‌اید",
    ACTIVE:"فعال",
    NOT_ACTIVE:"غیرفعال",
    DESCRIPTION:"توضیحات",
    DESCRIPTION_NOT_VALID:"توضیحات وارد شده باید بیشتر از ۱۰ و کمتر از ۲۵۵ حرف باشد",
    CATEGORY:"دسته",
    MAIN_PRICE:"قیمت اصلی (تومان)",
    PRICE:"قیمت بعد تخفیف (تومان)",
    MAIN_PRICE_CATALOG:"قیمت:",
    PRICE_CATALOG:"با تخفیف:",
    TOMAN:"تومان",
    FREE:"رایگان",
    PRICE_NOT_VALID:"لطفاً قیمت را وارد نمایید. در صورت عدم تخفیف، قیمت اصلی را وارد نمایید",
    MAIN_PRICE_NOT_VALID:"لطفاً قیمت را وارد نمایید. برای رایگان بودن عدد 0 را وارد نمایید",
    IMAGE_MAX_SIZE: "عکس انتخاب شده باید کمتر از ۱۰ مگابایت باشد",
}

export default item
