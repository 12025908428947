<template>
  <div class="card login-form">
    <back :title="FA_LAN.LOGIN.REGISTER "/>
    <form>
      <p class="small text-muted text-center">{{ FA_LAN.LOGIN.OPT_SENT_IN_PHONE }}</p>
      <div class="text-center">
        <button class="btn btn-sm btn-light" @click.prevent="toSendOtp">{{ getTimerOrResentText() }}</button>
      </div>
      <div class="mb-4">
        <label for="otp" class="form-label">{{ FA_LAN.LOGIN.OTP }}</label>
        <input type="number" class="form-control" id="otp" v-model.lazy="otp">
        <small class="text-danger" v-if="error">{{ FA_LAN.LOGIN.OTP_NOT_VALID }}</small>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-lg btn-success" @click.prevent="checkOtp" :disabled="loader">
          <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true" v-if="loader"></span>
          {{ FA_LAN.LOGIN.CHECK_OTP }}
        </button>
      </div>
      <div class="spacer"></div>
      <div>
        <span class="me-2">{{ FA_LAN.LOGIN.ALREADY_HAS_ACCOUNT }}</span>
        <router-link class="btn btn-sm btn-light" :to="{name:'Login'}">{{ FA_LAN.LOGIN.LOGIN }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useReCaptcha} from "vue-recaptcha-v3";
import {recaptchChek} from "@/store/webservice";
import {alert} from "@/js/alert/alert";
import {otpValidation, setInputNotValid, setInputValid} from "@/js/validation/input";
import {useRouter} from "vue-router";
import Back from "@/components/Back";

export default {
  name: "Check",
  components: {Back},
  setup() {
    const FA_LAN = persian;
    const otp = ref('');
    const error = ref(false);
    const loader = ref(false);
    const tokenValid = ref(false);
    const store = useStore();
    const router = useRouter();
    const inputID = ref('otp');
    const {executeRecaptcha, recaptchaLoaded} = useReCaptcha();
    const codeValidTime = computed(() => store.getters["apiKey/getTime"]);
    const timeRemain = ref('0:00');
    const resent = ref(false);

    onMounted(() => {
      setInterval(showValidTime, 1000);
    })

    async function checkOtp() {
      checkCode();

      if (error.value) {
        return;
      }

      loader.value = true

      await recaptcha();

      if (!tokenValid.value) {
        loader.value = false;
        return;
      }

      await store.dispatch('apiKey/checkOtp', otp.value);

      loader.value = false

      if (store.getters["apiKey/get"]) {
        await router.push({name: 'Fetch'});
      }
    }

    watch(otp, () => {
      if (otp.value.length !== 0) {
        checkCode();
      }
    })

    function checkCode() {
      const otpValid = otpValidation(otp.value);

      if (otpValid) {
        error.value = false;
        setInputValid(inputID.value);

      } else {
        error.value = true;
        setInputNotValid(inputID.value);

      }
    }

    async function recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha('login')

      // Do stuff with the received token.
      await recaptchChek(token)
          .then(function (response) {
            if (response.data.success === true && response.data.score >= .5) {
              tokenValid.value = true;

            } else {
              alert('error', FA_LAN.LOGIN.RECAPTCHA_ERROR)
              tokenValid.value = false;

            }
          })
    }

    function showValidTime() {
      const now = new Date();
      const codeValid = new Date(codeValidTime.value)
      let minutes, seconds;

      if (now >= codeValid) {
        minutes = 0;
        seconds = 0;

      } else {
        let diffInMilliSeconds = Math.abs(codeValid - now) / 1000;
        minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        seconds = Math.floor(diffInMilliSeconds) % 60;

      }

      timeRemain.value = minutes + ":" + seconds;
      resent.value = minutes == 0 && seconds == 0
    }

    function toSendOtp() {
      if (resent.value) {
        store.commit("apiKey/removeCodeSent");
      }
    }

    function getTimerOrResentText() {
      return resent.value ? FA_LAN.LOGIN.RESENT_OTP : timeRemain.value
    }

    return {FA_LAN, otp, error, loader, getTimerOrResentText, toSendOtp, checkOtp}
  }
}
</script>

<style scoped>

</style>