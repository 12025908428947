import {post} from "../webservice";
import {alert} from "@/js/alert/alert";


export const catalog = {
    namespaced: true,
    state: {
        catalogs: [],
        catalogID: null,
    },
    mutations: {
        set(state, catalogs) {
            state.catalogs = catalogs
        },
        setID(state, id) {
            state.catalogID = id;
        }
    },
    getters: {
        get(state) {
            return state.catalogs
        },
        getItem(state) {
            return state.catalogs.find(item => item.id == state.catalogID);
        },
        getItemId(state) {
            return state.catalogID;
        },
    },
    actions: {
        async getList(context) {
            await post('v1/catalog/getList')
                .then(function (response) {
                    if (response.data.code === 200) {
                        context.commit('set', response.data.data.catalogs);
                    } else {
                        alert('error', response.data.description)
                    }
                })
                .catch(function (error) {
                    alert('error', error)
                })
        }
    }
}