<template>
  <div>
    <div class="spacer m-md-1 m-2"></div>
    <div class="row align-items-center">
      <div class="col-md-4 col-12 mt-md-0 mt-2">
        <a :href="getImage(item.images)" target="_blank"><img :src="getImage(item.images)"></a>
      </div>
      <div class="col-md-8 col-12 mt-4 mb-4 ps-3">
        <div class="d-flex justify-content-between">
          <p class="p-0 m-0 mb-2">{{ item.name }}
            <span class="text-muted small ms-1">({{ item.category_name }}
              <span v-if="!getCategoryStatus(item.category_active)"
                    class="bg-light rounded-1 p-1">{{ FA_LAN.CATEGORY.NOT_ACTIVE }}</span>)
          </span>
          </p>
          <p class="p-0 m-0 small me-2">
            <!--            <span v-if="item.active" class="text-success p-1">{{ FA_LAN.ITEM.ACTIVE }}</span>-->
            <!--            <span v-else class="text-black-50 bg-light p-1">{{ FA_LAN.ITEM.NOT_ACTIVE }}</span>-->
            <span v-if="!item.active" class="text-black-50 bg-light rounded-1 p-1">{{ FA_LAN.ITEM.NOT_ACTIVE }}</span>
          </p>
        </div>
        <small class="text-muted">{{ item.description }}</small>
        <ItemBottom :item="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import ItemBottom from "@/components/item/ItemBottom";
import {getDefaultImage} from "@/js/image/image";

export default {
  name: "ShowItem",
  components: {ItemBottom},
  props: ['item'],
  setup() {
    const FA_LAN = persian;

    function getImage(images) {
      return images.length !== 0 ? images[0] : getDefaultImage('item');
    }

    function getCategoryStatus(status) {
      if (!status) {
        if (status === null) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }

    return {FA_LAN, getImage, getCategoryStatus}
  }
}
</script>

<style scoped>
.img-thumbnail {
  border: none !important
}
</style>