<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       id="pushRequets">
    <div class="category-form mx-auto mt-3 mb-3">
      <h6 v-if="actived" class="text-center text-success">{{ FA_LAN.PUSH.ACTIVE_BEFORE }}</h6>
      <div v-else>
        <p class="text-center">
          {{ FA_LAN.PUSH.BANNER_TITLE }}</p>
        <p class="text-muted small mb-3 text-center">{{ FA_LAN.PUSH.BANNER_DES }}</p>
        <div class="d-grid gap-2">
          <button type="button" class="btn btn-lg btn-success" @click="request">
            <span v-if="loader" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
            {{ FA_LAN.PUSH.INSTALL_NOW }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {onMounted, ref} from "vue";
import {alert} from "@/js/alert/alert";
import {post} from "@/store/webservice";
import {getMessaging, getToken} from "firebase/messaging";

export default {
  name: "PushRequest",
  setup(props, {emit}) {
    const FA_LAN = persian;
    const actived = ref(false);
    const loader = ref(false);

    onMounted(() => {
      checkActiveBefore();

      if ('serviceWorker' in navigator && 'PushManager' in window) {
        emit("showPush", true);

      } else {
        emit("showPush", false);

      }
    })

    function checkActiveBefore() {
      if (navigator.permissions) {
        navigator.permissions.query({name: 'notifications'})
            .then(response => {
              if (response.state === 'granted') {
                actived.value = true;
              }
            })
      }
    }

    function request() {
      Notification.requestPermission().then(response => {
        if (response === 'granted') {
          getAndSendToken();

        } else {
          alert('error', FA_LAN.PUSH.INSTALL_FAIL)
        }
      })
    }


    async function getAndSendToken() {
      loader.value = true;

      const messaging = getMessaging();
      getToken(messaging, {vapidKey: 'BLz7toO00so_vJh34Q-cBy0Eal5S1eslFWA1ePxAByNctI6ahAHpmUjeyln3TWDKkGSe6hfamXK2Cg9asU6RSXg'})
          .then((currentToken) => {
            if (currentToken) {
              post('v1/user/updateUID', {uid: currentToken});
            }
          })

      loader.value = false;

      alert('success', FA_LAN.PUSH.INSTALL_SUCCESS)
    }

    return {FA_LAN, actived, loader, request}
  }
}
</script>

<style scoped>

</style>