<template>
  <div class="bg-catalog"></div>
  <router-view v-slot="{ Component }">
    <transition name="slide-fade">
      <component :is="Component"/>
    </transition>
  </router-view>
  <UpdateLog/>
  <Footer/>
</template>

<script>

import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import Footer from "@/components/Footer";
import UpdateLog from "@/components/convas/UpdateLog";

export default {
  name: 'App',
  components: {UpdateLog, Footer},
  setup() {
    const store = useStore();
    const router = useRouter();
    const apiKey = computed(() => store.getters["apiKey/get"]);
    const login = ref(true);

    onMounted(async () => {
      preparePWABanner();

      await checkLogin();

      if (login.value) {
        await fetch();
      }
    })

    async function checkLogin() {
      if (apiKey.value) {
        await checkApiKey();
      }

      if (!apiKey.value) {
        login.value = false;
        await router.push({name: 'Login'})
      }
    }

    async function fetch() {
      await router.push({name: 'Fetch'})
    }

    async function checkApiKey() {
      await store.dispatch('apiKey/checkLogin');
    }

    function preparePWABanner() {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault(); //dont show automatic banner in old browser

        store.commit('pwa/set', {
          showBanner: true,
          event: e,
        })
      })
    }
  }
}
</script>
<style>
.slide-fade-enter-active {
  transition: all .8s ease;
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.loader {
  left: 50%;
  margin-left: -4em;
}
</style>
