<template>
  <div class="card">
    <Loading v-if="!catalog"/>
    <div v-else>
      <Header/>
      <div class="d-flex">
        <router-link class="btn btn-lg btn-success me-2" :to="{name:'NewItem'}">
          {{ FA_LAN.CATALOG.NEW_ITEM }}
        </router-link>
        <router-link class="btn btn-lg btn-secondary" :to="{name:'Category'}">
          {{ FA_LAN.CATALOG.CATEGORY_MANAGE }}
        </router-link>
      </div>
      <div class="spacer"></div>
      <div class="d-flex justify-content-around mb-4">
        <div class="col-11 text-center">
          <img :src="getImage(catalog.logo)" class="img-thumbnail rounded-circle square mb-2">
          <p class="m-0 p-0">{{ catalog.name }}</p>
          <small class="text-muted">{{ catalog.description }}</small>
        </div>
        <div class="col-1 d-flex justify-content-end align-items-start">
          <router-link class="btn btn-sm btn-light me-2" :to="{name:'EditCatalog'}">
            {{ FA_LAN.CATALOG.EDIT }}
          </router-link>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center alert alert-info p-2">
        <div class="text-center">
          {{ FA_LAN.CATALOG.CATALOG_URL }}
          <a :href="getFullUrl()" target="_blank" class="alert-info text-decoration-none">{{ getFullUrl() }}</a>
        </div>
        <div class="text-center">
          <!--        <a :href="getFullUrl()" target="_blank"-->
          <!--           class="btn btn-sm btn-outline-secondary">{{ FA_LAN.CATALOG.SHOW_CATALOG }}</a>-->
          <!--        <button @click="copyUrl" class="btn btn-sm btn-success ms-2 mt-md-0 mt-1">{{ FA_LAN.CATALOG.COPY_CATALOG_LINK }}-->
          <button @click="copyUrl" class="btn btn-sm btn-outline-secondary">
            {{ FA_LAN.CATALOG.COPY_CATALOG_LINK }}
            <i v-if="copied" class="far fa-check m-0"></i>
          </button>
        </div>
      </div>
      <p class="text-muted" v-if="checkEmpty()">{{ FA_LAN.ITEM.EMPTY_ITEM }}</p>
      <ShowItem v-else v-for="item in items" :key="item.id" :item="item"/>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import ShowItem from "@/components/item/ShowItem";
import {getDefaultImage} from "@/js/image/image";
import {alert} from "@/js/alert/alert";
import Header from "@/components/Header";
import Loading from "@/components/Loading";

export default {
  name: "Home",
  components: {Loading, Header, ShowItem},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const catalog = computed(() => store.getters["catalog/getItem"]);
    const items = computed(() => store.getters["item/get"]);
    const copied = ref(false);

    function checkEmpty() {
      return items.value.length === 0;
    }

    function getImage(image) {
      return image ? image : getDefaultImage('catalog')
    }

    function getFullUrl() {
      return FA_LAN.CATALOG.BASE_URL + catalog.value.username;
    }

    function copyUrl() {
      navigator.clipboard.writeText(getFullUrl());
      copied.value = true
      alert('success', FA_LAN.CATALOG.COPY_SUCCESS)
    }

    return {FA_LAN, catalog, items, copied, checkEmpty, getImage, getFullUrl, copyUrl}
  }
}
</script>

<style scoped>

</style>