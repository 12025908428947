const pwa={
    BANNER_DES:"با نصب برنامه اینستاکاتالوگ روی گوشی/کامپیوتر خود، راحت‌تر و سریع‌تر به پنل ادمین دسترسی داشته باشید",
    INSTALL_NOW:"بعد از کلیک، روی install/نصب، کلیک کنید",
    DENY:"بعداً",
    INSTALL_SUCCESS:"برنامه با موفقیت روی گوشی شما نصب شد",
    INSTALL_FAIL:"نصب برنامه با خطا مواجه شد",
    INSTALL_PANEL:"نصب برنامه اینستا کاتالوگ"
}

export default pwa
