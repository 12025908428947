<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       id="newcategory">
    <div class="category-form mx-auto mt-3 mb-4">
      <form>
        <div class="mb-4">
          <label for="name" class="form-label">{{ FA_LAN.CATEGORY.NAME }}</label>
          <input type="text" class="form-control" id="name" v-model.lazy="category.name">
          <small class="text-danger" v-if="error">{{ FA_LAN.CATEGORY.NAME_NOT_VALID }}</small>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-lg btn-success btn-block" @click.prevent="submit" :disabled="loader">
            <span v-if="loader" class="spinner-border spinner-border-sm me-1" role="status"
                  aria-hidden="true"></span>
            {{ FA_LAN.CATEGORY.SUBMIT }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {computed, reactive, ref, watch} from "vue";
import {setInputNotValid, setInputValid} from "@/js/validation/input";
import {post} from "@/store/webservice";
import {alert} from "@/js/alert/alert";
import {useStore} from "vuex";

export default {
  name: "NewCategory",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const inputID = ref('name');
    const loader = ref(false);
    const catalogID = computed(() => store.getters["catalog/getItemId"]);
    const category = reactive({
      catalog_id: catalogID.value,
      name: "",
    })
    const error = ref(false);

    watch(category, () => {
      if (category.name.length !== 0) {
        checkName()
      }
    })

    function checkName() {
      error.value = category.name.length <= 3;

      if (error.value) {
        setInputNotValid(inputID.value)
      } else {
        setInputValid(inputID.value)
      }
    }

    async function submit() {
      checkName();

      if (error.value) {
        return;
      }

      loader.value = true;

      await post('v1/catalog/category/new', category)
          .then(response => {
            if (response.data.code === 200) {
              alert('success', response.data.description);
            } else {
              alert('error', response.data.description);
            }
          })

      await getCategoryList();

      loader.value = false;
    }

    async function getCategoryList() {
      await store.dispatch('category/getList');
    }

    return {FA_LAN, category, error, loader, submit}
  }
}
</script>

<style scoped>

</style>