import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {VueReCaptcha} from 'vue-recaptcha-v3';
import VueGtag from "vue-gtag-next";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import './firebase';

const options = {
    position: "bottom-right",
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: true,
    timeout: 3000,
    newestOnTop: true,
    transition: "Vue-Toastification__bounce",
};

import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "./css/index.css"

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueReCaptcha, {siteKey: '6LcR8FseAAAAAI8S-8v0ZUAHyS3S3cqvldoo6SUe'})
app.use(VueGtag, {
    config: {
        id: "G-0YQC6S16S1", params: {
            send_page_view: false
        }
    }
});
app.use(Toast, options)
app.mount('#app')
