<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       id="updateLog">
    <div class="category-form mx-auto mt-3 mb-3">
      <div v-if="hasLog">
        <h6 class="mb-3 bt-2 text-center">{{ FA_LAN.UPDATE.TITLE }}</h6>
        <p class="mb-2">{{ FA_LAN.UPDATE.VERSION }}{{ updateLog.version }}
          <span class="ms-1 small text-muted">
            ({{ FA_LAN.UPDATE.PUBLISH_DATE }}{{ toJalaliDate(updateLog.published_at) }})
          </span>
        </p>
        <p class="mb-3 text-muted text-block">{{ updateLog.text }}</p>
        <div class="d-grid gap-2">
          <button type="button" class="btn btn-lg btn-success" data-bs-dismiss="offcanvas" data-bs-target="#updateLog">
            {{ FA_LAN.UPDATE.OK_BTN }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {onMounted, ref} from "vue";
import {post} from "@/store/webservice";
import {gregorian_to_jalali} from "@/js/date/convert";

export default {
  name: "UpdateLog",
  setup() {
    const FA_LAN = persian;
    const oldVersion = ref(localStorage.getItem('appVersion') ? localStorage.getItem('appVersion') : false);
    const currentVersion = ref(FA_LAN.MAIN.APP_VER_CODE);
    const updateLog = ref(null);
    const hasLog = ref(false);

    onMounted(async () => {
      await getLog();
    })

    async function getLog() {
      if (!oldVersion.value) {
        saveAppVer()
        return;
      }

      if (currentVersion.value <= oldVersion.value) {
        return;
      }

      await post('v1/update/getLastLog', {version_code: currentVersion.value})
          .then(response => {
            if (response.data.code == 200) {
              updateLog.value = response.data.data.update_log;
            }
          })

      if (!updateLog.value) {
        return;
      }

      hasLog.value = true;
      showBanner();

      saveAppVer();
    }

    function showBanner() {
      const app = document.getElementById("updateLog");
      app.classList.add('show');
      app.style.visibility = "visible";
      app.setAttribute("role", "dialog");
      app.setAttribute("aria-modal", "true")
    }

    function saveAppVer() {
      localStorage.setItem('appVersion', currentVersion.value);
    }

    function toJalaliDate(date) {
      return gregorian_to_jalali(date);
    }

    return {FA_LAN, updateLog, hasLog, toJalaliDate}
  }
}
</script>

<style scoped>
.text-block {
  white-space: pre;
}
</style>