<template>
  <div class="bg-back-button header mb-3">
    <button class="btn btn-link text-white p-3 text-decoration-none" @click="back()"><i class="fas fa-arrow-right me-2"></i>{{ title }}</button>
  </div>
</template>

<script>
import {useRouter} from "vue-router";

export default {
  name: "Back",
  props: ['title'],
  setup() {
    const router = useRouter();

    function back() {
      router.back();
    }

    return {back}
  }
}
</script>

<style scoped>

</style>