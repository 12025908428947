<template>
  <div class="card">

  </div>
</template>

<script>
import {computed, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  name: "List",
  setup() {
    const store = useStore();
    const router = useRouter();
    const catalogs = computed(() => store.getters["catalog/get"]);

    onMounted(() => {
      store.commit('catalog/setID', catalogs.value[0].id);
      router.push({name: "Fetch"})
    })
  }
}
</script>

<style scoped>

</style>