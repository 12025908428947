import {post} from "@/store/webservice";

export async function usernameValid(username,id) {

    let valid;
    await post('v1/catalog/checkUsername', {username: username, catalog_id: id})
        .then(response => {
            if (response.data.code === 200) {
                valid = true;

            } else {
                valid = false
            }
        })

    return valid;
}