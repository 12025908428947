<template>
  <ItemForm :exist-item="item"/>
</template>

<script>
import ItemForm from "@/components/item/ItemForm";
import {useRoute} from "vue-router";
import {computed, onMounted} from "vue";
import {useStore} from "vuex";

export default {
  name: "EditItem",
  components: {ItemForm},
  setup() {
    const route = useRoute();
    const store = useStore()
    const item = computed(() => store.getters["item/getItem"](route.params.id));

    onMounted(() => {
      scrollToTop();
    })

    function scrollToTop() {
      window.scrollTo(0, 0);
    }

    return {item}
  }
}
</script>

<style scoped>

</style>