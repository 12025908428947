const login={
    PHONE:"شماره همراه",
    PASSWORD:"گذرواژه",
    OTP:"کد ورود",
    LOGIN:"ورود به حساب کاربری",
    NOT_HAVE_ACCOUNT:"حساب کاربری ندارید؟",
    REGISTER:"ایجاد حساب کاربری",
    PHONE_NOT_VALID:"لطفاً شماره همراه را کامل و صحیح وارد کنید",
    PASSWORD_NOT_VALID:"لطفاً پسورد معتبری وارد نمایید",
    OTP_NOT_VALID:"کد ورود پیامک شده را به طور صحیح وارد نمایید",
    RECAPTCHA_ERROR:"کد ریکپچا تأیید نشد، مجدداً تلاش کنید",
    ALREADY_HAS_ACCOUNT:"قبلاً حساب کاربری ساخته‌اید؟",
    SEND_OTP:"ارسال کد ورود",
    CHECK_OTP:"بررسی کد ورود",
    OPT_SENT_IN_PHONE:"کد ورود از طریق پیامک برای شما ارسال شده است",
    RESENT_OTP:"ارسال مجدد کد ورود",
    EXIT:"خروج",
}

export default login
