<template>
  <ItemForm/>
</template>

<script>

import ItemForm from "@/components/item/ItemForm";

export default {
  name: "NewItem",
  components: {ItemForm},
}
</script>

<style scoped>

</style>