<template>
  <div class="card login-form">
    <Header :title="FA_LAN.LOGIN.REGISTER "/>
    <form>
      <div class="mb-4">
        <label for="phone" class="form-label">{{ FA_LAN.LOGIN.PHONE }}</label>
        <input type="number" class="form-control" id="phone" v-model.lazy="phone">
        <small class="text-danger" v-if="error">{{ FA_LAN.LOGIN.PHONE_NOT_VALID }}</small>
      </div>
      <div class="d-grid gap-2">
        <button class="btn btn-lg btn-success" @click.prevent="sendSms" :disabled="loader">
          <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true" v-if="loader"></span>
          {{ FA_LAN.LOGIN.SEND_OTP }}
        </button>
      </div>
      <div class="spacer"></div>
      <div>
        <span class="me-2">{{ FA_LAN.LOGIN.ALREADY_HAS_ACCOUNT }}</span>
        <router-link class="btn btn-sm btn-light" :to="{name:'Login'}">{{ FA_LAN.LOGIN.LOGIN }}</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {onMounted, ref, watch} from "vue";
import {get, recaptchChek} from "@/store/webservice";
import {useStore} from "vuex";
import {alert} from "@/js/alert/alert";
import {useReCaptcha} from "vue-recaptcha-v3";
import {phoneValidation, setInputNotValid, setInputValid} from "@/js/validation/input";
import Header from "@/components/Header";

export default {
  name: "Send",
  components: {Header},
  setup() {
    const FA_LAN = persian;
    const phone = ref('');
    const error = ref(false);
    const loader = ref(false);
    const tokenValid = ref(false);
    const store = useStore();
    const inputID = ref('phone');
    const {executeRecaptcha, recaptchaLoaded} = useReCaptcha();

    onMounted(() => {
      phone.value = store.getters["apiKey/getPhone"];
    })

    async function sendSms() {
      checkPhone();

      if (error.value) {
        return;
      }

      loader.value = true

      await recaptcha();

      if (!tokenValid.value) {
        loader.value = false;
        return;
      }

      await get(`v1/otp/send?phone=${phone.value}`)
          .then(function (response) {
            if (response.data.code === 200) {
              store.commit('apiKey/setCodeSent', phone.value);
            } else {
              alert('error', response.data.description)
            }
          })
          .catch(function (error) {
            alert('error', error)
          })

      loader.value = false

    }

    watch(phone, () => {
      if (phone.value.length !== 0) {
        checkPhone();
      }
    })

    function checkPhone() {
      const phoneNumber = phoneValidation(phone.value);

      if (phoneNumber) {
        error.value = false;
        setInputValid(inputID.value);
        phone.value = phoneNumber;

      } else {
        error.value = true;
        setInputNotValid(inputID.value);

      }
    }

    async function recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha('login')

      // Do stuff with the received token.
      await recaptchChek(token)
          .then(function (response) {
            if (response.data.success === true && response.data.score >= .5) {
              tokenValid.value = true;

            } else {
              alert('error', FA_LAN.LOGIN.RECAPTCHA_ERROR)
              tokenValid.value = false;

            }
          })
    }

    return {FA_LAN, phone, error, loader, sendSms}
  }
}
</script>

<style scoped>

</style>