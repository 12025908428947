<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       id="contactus">
    <div class="category-form mx-auto mt-3 mb-3">
      <div>
        <p class="text-muted mb-3 text-center">{{ FA_LAN.CONTACTUS.DESCRIPTION }}</p>
        <div class="row">
          <div class="d-grid gap-2 col-6">
            <a type="button" class="btn btn-lg btn-success" href="https://api.whatsapp.com/send?phone=+989306242818"
               target="_blank">
              {{ FA_LAN.CONTACTUS.WHATSAPP }}
              <i class="fa-brands fa-whatsapp ms-1"></i>
            </a>
          </div>
          <div class="d-grid gap-2 col-6">
            <a type="button" class="btn btn-lg btn-info text-white ms-1" href="tg://resolve?domain=negahgroup_ir" target="_blank">
              {{ FA_LAN.CONTACTUS.TELEGRAM }}
              <i class="fa-brands fa-telegram ms-1"></i>
            </a>
          </div>
        </div>
        <div class="mt-3 mb-3">
          <p class="alert alert-warning text-center" role="alert">{{ FA_LAN.CONTACTUS.NO_CALL }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";

export default {
  name: "ContactUs",
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>