<template>
  <div class="offcanvas offcanvas-bottom" tabindex="-1" aria-labelledby="offcanvasBottomLabel"
       id="review">
    <div class="category-form mx-auto mt-3 mb-3">
      <form>
        <div class="mb-4">
          <label class="form-label">{{ FA_LAN.REVIEW.TEXT }}</label>
          <textarea class="form-control" id="reviewText" v-model.lazy="text" rows="4"></textarea>
          <small class="text-danger" v-if="error">{{ FA_LAN.REVIEW.TEXT_NOT_VALID }}</small>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-lg btn-success btn-block" @click.prevent="submit" :disabled="loader">
            <span v-if="loader" class="spinner-border spinner-border-sm me-1" role="status"
                  aria-hidden="true"></span>
            {{ FA_LAN.REVIEW.SUBMIT }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {ref, watch} from "vue";
import {setInputNotValid, setInputValid} from "@/js/validation/input";
import {post} from "@/store/webservice";
import {alert} from "@/js/alert/alert";

export default {
  name: "Review",
  setup() {
    const FA_LAN = persian;
    const text = ref('');
    const loader = ref(false);
    const inputID = ref('reviewText');
    const error = ref(false);

    watch(text, () => {
      if (text.value.length !== 0) {
        checkText();
      }
    })

    async function submit() {
      checkText()

      if (error.value) {
        return;
      }

      loader.value = true;

      await post('v1/feedback/new', {text: text.value})
          .then(response => {
            if (response.data.code === 200) {
              alert('success', response.data.description);
              text.value = '';
            } else {
              alert('error', response.data.description);
            }
          })

      loader.value = false;
    }

    function checkText() {
      if (text.value.length <= 5) {
        error.value = true;
        setInputNotValid(inputID.value)

      } else {
        error.value = false;
        setInputValid(inputID.value)

      }
    }

    return {FA_LAN, text, error, loader, submit}
  }
}
</script>

<style scoped>

</style>