import {post} from "@/store/webservice";
import {alert} from "@/js/alert/alert";

export async function uploadImage(file, role) {
    const formData = new FormData();
    formData.append("image", file);
    let result = false;

    await post(`v1/catalog/images/upload?role=${role}`, formData, true)
        .then(response => {
            if (response.data.code === 200) {
                result = response.data.data.file_id;

            } else {
                alert('error', response.data.description);
            }
        })

    return result;
}