import {post} from "../webservice";
import {alert} from "@/js/alert/alert";
import store from '@/store/index'

export const item = {
    namespaced: true,
    state: {
        items: [],
    },
    mutations: {
        set(state, items) {
            state.items = items
        },
    },
    getters: {
        get(state) {
            return state.items
        },
        getItem: (state) => (id) => {
            return state.items.find(item => item.id == id);
        }
    },
    actions: {
        async getList(context) {
            const id = store.getters["catalog/getItemId"]

            await post('v1/catalog/item/getList', {catalog_id: id})
                .then(function (response) {
                    if (response.data.code === 200) {
                        context.commit('set', response.data.data.items);
                    } else {
                        alert('error', response.data.description)
                    }
                })
                .catch(function (error) {
                    alert('error', error)
                })
        }
    }
}