<template>
  <Loading/>
</template>

<script>
import Loading from "@/components/Loading";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {computed, onMounted} from "vue";
import {setCssColor} from "@/js/css/color";

export default {
  name: "Fetch",
  components: {Loading},
  setup() {
    const router = useRouter();
    const store = useStore();
    const catalogs = computed(() => store.getters["catalog/get"])
    const catalog = computed(() => store.getters["catalog/getItem"]);

    onMounted(async () => {
      await store.dispatch('catalog/getList');

      if (catalogs.value.length === 0) {
        await router.push({name: "NewCatalog"})
        return;
      }

      if (!store.getters["catalog/getItemId"]) {
        await router.push({name: "CatalogList"})
        return;
      }

      await store.dispatch('category/getList')
      await store.dispatch('item/getList')

      if(!catalog.value){
        await router.push({name: "CatalogList"})
        return;
      }

      setCssColor(catalog.value.background_color, catalog.value.text_color)

      await router.push({name: 'Home'})
    })
  }
}
</script>

<style scoped>

</style>