<template>
  <div>
    <div class="header bg-light mb-3">
      <div class="align-items-center justify-content-between text-center p-2" :class="{'d-flex':apiKey}">
        <p class="m-0 ms-2">{{ FA_LAN.MAIN.APP_NAME }}<span class="text-muted small ms-2">{{
            FA_LAN.MAIN.APP_VER
          }}</span>
        </p>
        <div v-if="apiKey" class="btn-group" role="group">
          <button id="dLabel" class="btn btn-lg btn-link text-body text-decoration-none dropbtn" type="button"
                  data-toggle="dropdown" aria-haspopup="false" aria-expanded="false" @click="showMenu">
            <i class="far fa-bars"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="dLabel" id="menu">
            <button class="dropdown-item small p-2" data-bs-toggle="offcanvas" data-bs-target="#pushRequets"
                    aria-controls="offcanvasExample" v-if="showPush">{{ FA_LAN.PUSH.ACTIVE_PUSH }}
            </button>
            <button class="dropdown-item small p-2" data-bs-toggle="offcanvas" data-bs-target="#pwa"
                    aria-controls="offcanvasExample" v-if="showPWA">{{ FA_LAN.PWA.INSTALL_PANEL }}
            </button>
            <button class="dropdown-item small p-2" data-bs-toggle="offcanvas" data-bs-target="#review"
                    aria-controls="offcanvasExample">{{ FA_LAN.REVIEW.NEW }}
            </button>
            <button class="dropdown-item small p-2" data-bs-toggle="offcanvas" data-bs-target="#contactus"
                    aria-controls="offcanvasExample">{{ FA_LAN.CONTACTUS.TICKET }}
            </button>
            <button class="dropdown-item small text-danger p-2" @click="logout">{{ FA_LAN.LOGIN.EXIT }}</button>
          </div>
        </div>
      </div>
    </div>
    <h6 class="text-center mb-2 text-info mb-3">{{ title }}</h6>
    <PWABanner/>
    <PushRequest @showPush="showPushButton"/>
    <Review/>
    <ContactUs/>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {computed, ref} from "vue";
import Review from "@/components/Review";
import PWABanner from "@/components/convas/PWABanner";
import PushRequest from "@/components/convas/PushRequest";
import ContactUs from "@/components/convas/ContactUs";

export default {
  name: "Header",
  components: {ContactUs, PushRequest, PWABanner, Review},
  props: ['title'],
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const router = useRouter();
    const apiKey = computed(() => store.getters["apiKey/get"]);
    const showPush = ref(false);
    const showPWA = computed(() => store.getters["pwa/getShowBanner"]);

    function showMenu() {
      const menu = document.getElementById('menu');
      menu.classList.add('show')
    }

// Close the dropdown menu if the user clicks outside of it
    window.onclick = function (event) {

      if (!event.target.matches('.dropbtn') && !event.target.matches('.fa-bars')) {
        const menu = document.getElementById('menu');
        menu.classList.remove('show')
      }
    }

    async function logout() {
      await store.dispatch('apiKey/logout');

      await router.push({name: "Login"});
    }

    function showPushButton(status) {
      showPush.value = status;
    }

    return {FA_LAN, apiKey, showPWA, showPush, showMenu, showPushButton, logout}
  }
}
</script>

<style scoped>
.dropdown-menu {
  position: absolute;
  transform: translate3d(0px, 48px, 0px);
  top: -10px;
  left: 0px;
  will-change: transform;
  margin: 0.125rem 0 0;
  padding: 0px;
}
</style>