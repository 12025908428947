export const pwa = {
    namespaced: true,
    state: {
        showBanner: false,
        event: null,
    },
    mutations: {
        set(state, pwa) {
            state.showBanner = pwa.showBanner
            state.event = pwa.event
        },
    },
    getters: {
        getShowBanner(state) {
            return state.showBanner
        },
        getEvent(state) {
            return state.event
        }
    },

}