<template>
  <div>
    <div class="mt-2 p-2 bg-light border-2">
      <div class="d-flex ">
        <div class="col-6 m-0 p-0">
          <label class="form-label">{{ FA_LAN.ITEM.MAIN_PRICE }}</label>
          <input type="number" class="form-control" :id="'mainPrice'+item.id" v-model.number="updatedItem.main_price">
        </div>
        <div class="col-6 m-0 p-0 ms-1">
          <label class="form-label off-price">{{ FA_LAN.ITEM.PRICE }}</label>
          <input type="number" class="form-control" :id="'price'+item.id" v-model.number="updatedItem.price">
        </div>
      </div>
      <small class="text-danger" v-if="error.main_price">{{ FA_LAN.ITEM.MAIN_PRICE_NOT_VALID }}</small>
      <small class="text-danger" v-if="error.price">{{ FA_LAN.ITEM.PRICE_NOT_VALID }}</small>
    </div>
    <div class="d-flex justify-content-end bg-light">
      <button class="btn btn-sm btn-success" @click.prevent="update"
              :disabled="loaderDel || loaderSave">
            <span v-if="loaderSave" class="spinner-border spinner-border-sm me-1" role="status"
                  aria-hidden="true"></span>
        {{ FA_LAN.ITEM.SAVE }}
      </button>
      <router-link class="btn btn-sm btn-secondary ms-2" :to="{name:'EditItem',params:{id:item.id}}"
                   :disabled="loaderDel || loaderSave">
        {{ FA_LAN.ITEM.EDIT }}
      </router-link>
      <button class="btn btn-sm btn-danger ms-2" @click.prevent="deleteItem"
              :disabled="loaderDel || loaderSave">
            <span v-if="loaderDel" class="spinner-border spinner-border-sm me-1" role="status"
                  aria-hidden="true"></span>
        {{ FA_LAN.ITEM.DELETE }}
      </button>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {reactive, ref, watch} from "vue";
import {post} from "@/store/webservice";
import {alert} from "@/js/alert/alert";
import {useStore} from "vuex";
import {setInputNotValid, setInputValid} from "@/js/validation/input";

export default {
  name: "ItemBottom",
  props: ['item'],
  setup(props) {
    const FA_LAN = persian;
    const loaderDel = ref(false);
    const loaderSave = ref(false);
    const store = useStore();
    const error = reactive({
      main_price: false,
      price: false,
    });

    const inputID = reactive({
      main_price: 'mainPrice' + props.item.id,
      price: 'price' + props.item.id,
    });

    const updatedItem = reactive({
      item_id: props.item.id,
      category_id: props.item.catalog_category_id,
      name: props.item.name,
      description: props.item.description,
      images: null,
      main_price: props.item.main_price,
      price: props.item.price,
      active: true
    })

    watch(updatedItem, () => {
      if (updatedItem.main_price.length !== 0) {
        checkMainPrice();
      }

      if (updatedItem.price.length !== 0) {
        checkPrice();
      }
    })

    async function update() {
      checkPrice();
      checkMainPrice();

      if (error.main_price || error.price) {
        return;
      }

      loaderSave.value = true;
      console.log(updatedItem);
      await post('v1/catalog/item/update', updatedItem)
          .then(response => {
            if (response.data.code === 200) {
              alert('success', response.data.description);
            } else {
              alert('error', response.data.description);
            }
          })

      await getItemList();

      loaderSave.value = false;
    }

    async function deleteItem() {
      loaderDel.value = true;

      await post('v1/catalog/item/delete', {item_id: props.item.id})
          .then(response => {
            if (response.data.code === 200) {
              alert('success', response.data.description);
            } else {
              alert('error', response.data.description);
            }
          })

      await getItemList();

      loaderDel.value = false;

    }

    async function getItemList() {
      await store.dispatch('item/getList');
    }

    function checkMainPrice() {
      error.main_price = updatedItem.main_price.length <= 0;

      if (error.main_price) {
        setInputNotValid(inputID.main_price)
      } else {
        setInputValid(inputID.main_price)
      }
    }

    function checkPrice() {
      error.price = updatedItem.price.length <= 0;

      if (error.price) {
        setInputNotValid(inputID.price)
      } else {
        setInputValid(inputID.price)
      }
    }

    return {FA_LAN, loaderDel, loaderSave, updatedItem, error, update, deleteItem}
  }
}
</script>

<style scoped>

</style>