import persian from "@/fa-IR";
import {useToast} from "vue-toastification";

export function alert(type, message) {

    if (message == "Error: Network Error") { //if no internet access
        message = persian.MAIN.NO_INTERNET;
    }

    const toast = useToast();

    if (type === 'success') {
        toast.success(message)

    } else if (type === 'error') {
        toast.error(message)

    } else if (type === 'warning') {
        toast.warning(message)

    } else if (type === 'info') {
        toast.info(message)

    }
}