<template>
  <div>
    <label for="username" class="form-label">{{ FA_LAN.CATALOG.USERNAME }}</label>
    <div class="d-flex align-items-center">
      <input type="text" class="form-control ltr col-7" id="username" v-model.lazy="updatedUsername">
      <p class="col-5 text-muted small ltr m-0">{{ FA_LAN.CATALOG.BASE_URL }}</p>
    </div>
    <small class="text-danger" v-if="error.username">{{ FA_LAN.CATALOG.USERNAME_NOT_VALID }}</small>
    <small class="text-danger" v-if="error.usernameShort">{{ FA_LAN.CATALOG.USERNAME_SHORT }}</small>
  </div>
</template>

<script>
import persian from "@/fa-IR";
import {ref, watch} from "vue";


export default {
  name: "Username",
  props: ['username', 'error'],
  setup(props, {emit}) {
    const FA_LAN = persian;
    const updatedUsername = ref(props.username);

    watch(updatedUsername, async () => {
      emit('update:modelValue', updatedUsername.value);

    })

    return {FA_LAN, updatedUsername}
  }
}
</script>

<style scoped>
.col-7 {
  width: 58.33333333% !important;
}

.ltr {
  direction: ltr;
}

.btn-sm {
  padding: 0.25rem 0.5rem !important;
}
</style>